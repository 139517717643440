import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './page/Main';
import NotFoundPage from './page/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/email/:id" element={<Main />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
