import axios from "axios";
import React, { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import successIcon from "../assets/images/svg/success-icon.svg";
import errorIcon from "../assets/images/svg/sad-face-icon.svg";

const Main = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  let match = useMatch(`/email/:id`);
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://kabani7.aellapp.com/"
      : process.env.REACT_APP_BASEURL;
  const verifyToken = async () => {
    const reqBody = {
      token: match.params.id,
    };

    try {
      const data = await axios({
        method: "POST",
        url: `${baseUrl}onboard/verify-email`,
        data: reqBody,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccess(data);
    } catch (error) {
      if (error.response.data.message) {
        setError(error.response.data.message);
      } else {
        window.location.replace("/");
      }
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {success && (
        <div className='translate-y-2/3 bg-white flex flex-col items-center'>
          <div className='rounded-md bg-[#F3F5FC] py-4 px-5 w-[242px] md:w-[450px] md:py-10 md:px-8 flex flex-col items-center justify-center'>
            <div className='h-[30px] w-[30px]'>
              <img src={successIcon} alt='success icon' />
            </div>
            <h1 className='leading[18px] text-lg text-[#2054D2] mt-2.5'>
              Verified!
            </h1>
            <p className='whitespace-pre-line text-sm leading-[18px] text-[#4F4F4F] font-normal text-center mt-2.5'>
              You have successfully verified your account
            </p>
          </div>
          <a href='https://aellaapp.com/'>
            <button
              className='flex mt-[26px] px-[80px] py-[11px]  text-[#FFF6EA] rounded-md bg-[#2054D2] items-center transition ease-in-out duration-200 translate-10 font-normal text-base'
              type='button'
            >
              Launch App
            </button>
          </a>
        </div>
      )}
      {error && (
        <div className='translate-y-72 bg-white flex flex-col items-center'>
          <div className='rounded-md bg-[#F3F5FC] py-4 px-5 w-[242px] md:w-[450px] md:py-10 md:px-8 flex flex-col items-center justify-center'>
            <div className='h-[30px] w-[30px]'>
              <img src={errorIcon} alt='success icon' />
            </div>
            <h1 className='leading[18px] text-lg text-[#2054D2] mt-2.5'>
              Invalid Verification
            </h1>
            <p className='whitespace-pre-line text-sm leading-[18px] text-[#4F4F4F] font-normal text-center mt-2.5'>
              {error}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default Main;
