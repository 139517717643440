import React from "react";
import errorIcon from "../assets/images/svg/sad-face-icon.svg";

const NotFoundPage = () => {
  return (
    // <div className="translate-y-72 flex justify-center">
    //   <p className="text-base font-md">404 NOT FOUND</p>
    // </div>
    <div className='translate-y-72  bg-white flex flex-col items-center'>
      <div className='rounded-md bg-[#F3F5FC] py-4 px-5 w-[242px] md:w-[450px] md:py-10 md:px-8 flex flex-col items-center justify-center'>
        <div className='h-[30px] w-[30px]'>
          <img src={errorIcon} alt='success icon' />
        </div>
        <h1 className='leading[18px] text-lg text-[#2054D2] mt-2.5'>
          Invalid Verification
        </h1>
        <p className='whitespace-pre-line text-sm leading-[18px] text-[#4F4F4F] font-normal text-center mt-2.5'>
          Unable to verify email. Kindly confirm the link.
        </p>
      </div>
    </div>
  );
};
export default NotFoundPage;
